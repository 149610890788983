import 'leaflet/leaflet.js';

function newMarker(item, map, customIcon){
    if(item.lat && item.lng) {
        var marker = L.marker([item.lat, item.lng], {icon: customIcon}).addTo(map).bindPopup(item.content).bindTooltip(item.title);
    }
}

function generateMap(item) {

    var leaflet = item.data('leaflet');

    var customIcon = L.icon({
        iconUrl: leaflet.themeUrl+'/img/marker.png',
        iconSize: [25, 41],
        shadowUrl: leaflet.themeUrl+'/img/marker-shadow.png',
        shadowSize: [41, 41],
        shadowAnchor: [13, 41],
        iconAnchor: [13, 41],
        popupAnchor: [0, -33],
        tooltipAnchor: [0, 0]
    });

    var customIconActive = customIcon;

    /*
    * map options
    */
    let map = L.map('map-canvas-'+leaflet.id, {
        center: [leaflet.center.lat, leaflet.center.lng],
        zoom: leaflet.zoom,
        scrollWheelZoom: leaflet.scrollWheelZoom,
        zoomControl: leaflet.showMapUi
        });

    /**
     * copyright
     */
    var attributeControlPosition = (leaflet.copyrightPosition) ? leaflet.copyrightPosition : "bottomright";
        map.attributionControl.addAttribution('&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>-Mitwirkende').setPosition(attributeControlPosition);

        /**
         * map tiles
         */
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

        let markers = leaflet.markers;

        $.each(markers, function(index, marker) {
            newMarker(marker, map, customIcon);
        });
}

$(function() {
    $('[data-leaflet]').each((i, item) => {
        generateMap($(item));
    });
});