// load all images from theme_url/img
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./img', true));


// import leaflet
import './js/leaflet';


// import bootstrap js
import './js/bootstrap.js';

// tenor js
import Tenor from 'tenor/init';
new Tenor();

import { LuminousGallery } from 'luminous-lightbox';

new LuminousGallery(document.querySelectorAll("a.gallery-link"));

// custom scss
import './scss/app.scss';

$(function() {
    $('.navbar-toggle-button').click(function() {
        $('#main-nav').css('display', 'block');
        $('body,html').css('overflow', 'hidden');
        $('body,html').css('max-height', '100%');
    });

    $('#close-nav .fa').click(function() {
        $('#main-nav').css('display', 'none');
        $('body,html').css('overflow', ' auto');
        $('body,html').css('max-height', 'none');
    });

    const manufacturerSelect = $('#selectManufacturer');
    const carConfigSubmitButton = manufacturerSelect.parents('form').find('button[type="submit"]');

    if($('#selectManufacturer').val()) {
        carConfigSubmitButton.prop("disabled", false);
    } else {
        carConfigSubmitButton.prop("disabled", true);
    }
});

$('#selectManufacturer').change(function() {
    var manufacturer = $('#selectManufacturer').val();
    var url = $('#selectManufacturer').attr('data-target-link');

    const button = $(this).parents('form').find('button[type="submit"]');

    if($(this).val()) {
        button.prop("disabled", false);
    } else {
        button.prop("disabled", true);
    }

    $('#selectModel').empty();
    $('#selectModel').append('<option value="">Bitte Modell wählen</option>');

    $('#selectEngine').empty();
    $('#selectEngine').append('<option value="">Bitte Motor wählen</option>');

    if (manufacturer != '') {
        $.getJSON(url + 'manufacturer=' + manufacturer, function(data) {
            $('#selectModel').removeAttr('disabled');
            for (var i = 0; i < data.length; i++) {
                $('#selectModel').append('<option value="' + data[i].id + '" data-link="' + data[i].link + '">' + data[i].name + '</option>');
            }
        });
    }
    else {
        $('#selectModel').attr('disabled', 'disabled');
        $('#selectEngine').attr('disabled', 'disabled');
    }
});

$('#selectModel').change(function() {
    var manufacturer = $('#selectManufacturer').val();
    var model = $('#selectModel').val();
    var url = $('#selectModel').attr('data-target-link');

    $('#selectEngine').empty();
    $('#selectEngine').append('<option value="">Bitte Motor wählen</option>');

    if (model != '') {
        $.getJSON(url + 'manufacturer=' + manufacturer + '&model=' + model, function(data) {
            $('#selectEngine').removeAttr('disabled');

            for (var i=0; i<data.length; i++) {
                $('#selectEngine').append('<option value="' + data[i].id  + '" data-link="' + data[i].link + '">' + data[i].name + '</option>');
            }
        });
    }
    else {
        $('#selectEngine').attr('disabled', 'disabled');
    }
});

// $('#selectEngine').change(function() {
//     var engine = $('#selectEngine').val();
//
//     if (engine != '') {
//         $('.CarConfigurator .actions button').addClass('active');
//         $('.CarConfigurator .actions button').removeAttr('disabled', 'disabled');
//     }
//     else {
//         $('.CarConfigurator .actions button').removeClass('active');
//         $('.CarConfigurator .actions button').attr('disabled', 'disabled');
//     }
//
// });

$(".CarConfigurator form").submit(function() {
    let url;

    if ($('#selectEngine').val() != '') {
        url = $('#selectEngine option:selected').data('link');
    }
    else if ($('#selectModel').val() != '') {
        url = $('#selectModel option:selected').data('link');
    }
    else if ($('#selectManufacturer').val() != '') {
        url = $('#selectManufacturer option:selected').data('link');
    }

    if (url != '') {
        location.href = url;
    }

    return false;
});

/** ganze Box verlinken Funktion **/
function linkWholeBox(cssClass) {
    var container = $(cssClass);

    if(container.find('a').length > 0) {
        container.css('cursor', 'pointer');

        container.click(function(){
            location.href = $(this).find('a').attr('href');
        });

        container.mouseover(function(){
            $(this).attr('title', $(this).find('a').attr('title'));
        });
    }
}


/** Alle Boxen verlinken **/
$(function() {
    linkWholeBox('.tuning-services');
    linkWholeBox('.cart-navigation')
});

/** Close Success Message **/
$('.close-success-message').click(function() {
    $('.success-message').css('display', 'none');
});

$(function() {
    // get cookies
    var cookie = require('cookie');
    var cookies = cookie.parse(document.cookie);

    if (!cookies.popupShown) {
        // show modal
        $('#dt_modalPopup').modal('show');

        // close event
        $('#dt_modalPopup').on('hide.bs.modal', function (event) {
          // set cookie
          document.cookie = cookie.serialize('popupShown', 1, {
              sameSite: true
          });
        })


    }
});